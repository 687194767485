import dayjs from "dayjs";

export function convertImageToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      resolve(reader.result as string);
    };

    reader.onerror = (error) => {
      reject(error);
    };
  });
}


export function convertDate(dateString: string) {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1; // Month is zero-based, so we add 1
  const year = date.getFullYear();
  return year + '-' + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
}


export function formatTimestamp(timestamp: string) {
  const date = new Date(timestamp);

  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${hours}:${minutes} ${day}.${month}.${year}`;
}

export function formatTimestampToServerDate(timestamp: string) {
  const date = new Date(timestamp);
  if (date.toString() === 'Invalid Date') return "";

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
}

export function stringToDayJs(date: string | null) {
  return date ? dayjs(date) : null;
}

export function transformDate(dateString: string) {
  const [day, month, year] = dateString.split('-');

  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const monthName = monthNames[parseInt(month, 10) - 1];
  return `${monthName} ${parseInt(day, 10)}, ${year}`;
}

export const getDaysToDomainExpiry = (expiredDateStr: any): any => {
  const expiredDate: any = new Date(expiredDateStr);
  const currentDate: any = new Date();


  expiredDate.setHours(0, 0, 0, 0);
  currentDate.setHours(0, 0, 0, 0);

  return Math.ceil((expiredDate - currentDate) / (1000 * 60 * 60 * 24));
}