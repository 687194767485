import React from "react";
import styles from "./UserPopUp.module.scss";
import logoutPic from "../../img/logout.svg";
import supportPic from '../../img/support.svg';
import { useNavigate } from "react-router-dom";
import { deleteToken } from "../../helpers/jwtHelpers";


export const UserPopUp = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.wrapper}>
      <div className={styles.name}>
      </div>

      <div className={styles.section}>
        <img src={supportPic} width={24} height={24} alt="support icon"/>
        <a
          href="https://t.me/TL_supp_wwa_vados"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.support}>
          Поддержка
        </a>
      </div>
      <button type="button" className={styles.logout}>
        <img src={logoutPic} width={24} height={24} alt="lofo icon"/>
        <p onClick={() => {
          deleteToken();
          navigate('/login');
        }}>Выйти</p>
      </button>
    </div>
  );
};
