import React, {useState} from 'react';
import {Checkbox} from "../../Checkbox/Checkbox";
import {deselectPWA, selectPWA} from "../../../store/reducers/pwaListReducer";
import styles from "../PwaTable.module.scss";
import {Link} from "react-router-dom";
import {Status} from "../../../types/api";
import {Tooltip} from "@mui/material";
import {TableTools} from "../../TableTools/TableTools";
import {useDispatch} from "react-redux";
import {getDaysToDomainExpiry} from "../../../helpers/formatData";
import SvgSelector from "../../SvgSelector/SvgSelector";

const PwaTr = ({item, checkedPWAs, statusToLabel, index}) => {
    const [isVisibleExpiringDomainTooltip, setIsVisibleExpiringDomainTooltip] = useState(false);

    const dispatch = useDispatch()


    const toggleTooltip = () => setIsVisibleExpiringDomainTooltip(!isVisibleExpiringDomainTooltip);


    const tooltipEventHandlers = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
        ? {onClick: toggleTooltip}
        : {
            onMouseEnter: () => setIsVisibleExpiringDomainTooltip(true),
            onMouseLeave: () => setIsVisibleExpiringDomainTooltip(false),
        };

    const daysToDomainExpiry = getDaysToDomainExpiry(item?.domain?.expires_on)

    return (
        <tr>
            <td>
                <Checkbox
                    checked={checkedPWAs.has(item.id)}
                    onChange={(e) => {
                        if (e.target.checked) {
                            dispatch(selectPWA(item.id));
                        } else {
                            dispatch(deselectPWA(item.id));
                        }
                    }}
                />
            </td>
            <td className={styles.nameV}> {
                item.icon && <img src={item.icon} alt="pwa icon" className={styles.icon}/>
            }<Link to={`/edit/${item.id}`} className={styles.link}>{item.name}</Link></td>
            <td className={item.status === Status.in_process ? styles.status : styles.statusV}>{
                item.status
                    ? statusToLabel[item.status]
                    : ""
            }</td>
            <td className={item?.domain?.address && styles.domain}>
                <div className={styles.domainTdContent}
                >
                    <a
                        href={item?.domain?.address && `https://${item?.domain?.address}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`
                            ${styles.domainName}
                             ${daysToDomainExpiry < 1 ? styles.red : ''}
                              ${daysToDomainExpiry <= 14 && daysToDomainExpiry > 0 ? styles.orange : ''}
                              `}
                    >
                        {item?.domain?.address || "—"}
                    </a>
                    {
                        daysToDomainExpiry <= 14
                        && <button
                            {...tooltipEventHandlers}
                            className={`${styles.tooltipButton} ${daysToDomainExpiry > 1 ? styles.orange : styles.red}`}
                        >
                            <SvgSelector id={daysToDomainExpiry < 1 ? "expired-domain-icon" : "expiring-domain-icon"}/>
                            {
                                isVisibleExpiringDomainTooltip
                                && <div
                                    className={styles.domainTooltip}
                                    style={
                                        index === 0
                                            ? {transform: 'translateY(70%)'}
                                            : null
                                    }
                                >
                                    <span className={styles.highlighted}>Внимание!{" "}</span>
                                    {
                                        daysToDomainExpiry < 1
                                            ? "Cрок работы домена истек"
                                            : `Срок работы домена истекает через: ${daysToDomainExpiry} 
                                            ${daysToDomainExpiry >= 5 ? 'дней' : ''}
                                            ${daysToDomainExpiry >= 2 && daysToDomainExpiry < 5 ? 'дня' : ''}
                                            ${daysToDomainExpiry === 1 ? 'день' : ''}`
                                    }
                                </div>
                            }
                        </button>
                    }
                </div>
            </td>
            <td className={styles.statusV}>
                {item.vertical}
            </td>
            <td>
                <div className={styles.countriesListContainer}>
                    {item?.countries?.length > 0 ?
                        item.countries.length > 4
                            ? <Tooltip title={item?.countries && item?.countries?.join(', ')}>
                                <div className={styles.countriesList}>
                                    {item?.countries && item?.countries.join(', ')}
                                </div>
                            </Tooltip>
                            : <div className={styles.countriesList}>
                                {item?.countries && item?.countries.join(', ')}
                            </div> : "Все страны"
                    }
                </div>
            </td>
            <td>
                <TableTools pwaData={item}/>
            </td>
        </tr>
    )
}

export default PwaTr;