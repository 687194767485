import React, { useEffect, useState } from "react";

import styles from "./DateSelect.module.scss";
import { Select } from "./Select";
import { dateRangeOptions } from "../../helpers/lists";
import { getDateRange, getDateRangeValues, SelectedDateRange } from "../../helpers/dateHelpers";
import { params } from "../../api/helpers/constants";
import { DatePicker } from "../DatePicker/DatePicker";
import { formatTimestampToServerDate, stringToDayJs } from "../../helpers/formatData";
import { useSearchParams } from "react-router-dom";

const DEFAULT_DATE_OPTION = "Выберите дату";

// interface DateSelectProps {
//   showForToday?: boolean;
// }

export const DateSelect: React.FC = () => {
  const [selectedDateOption, setSelectedDateOptions] = useState(DEFAULT_DATE_OPTION);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if(searchParams.get(params.date_after) || searchParams.get(params.date_before)) {
      const ranges = getDateRangeValues();
      const range = ranges[`${searchParams.get(params.date_after)}-${searchParams.get(params.date_before)}`];
      if (range) {
        setSelectedDateOptions(range);
      } else {
        setSelectedDateOptions(SelectedDateRange.CustomDate);
      }
    }
  }, []);


  useEffect(() => {
    if(!searchParams.get(params.date_after) && !searchParams.get(params.date_before)) {
        setSelectedDateOptions(SelectedDateRange.Today);
        const range = getDateRange(SelectedDateRange.Today as SelectedDateRange);


        if (range) {
          setSearchParams(_params => {
            _params.set(params.date_after, range.startDate);
            _params.set(params.date_before, range.endDate);
            // _params.set(params.page, '1');
            return _params;
          });
        }
    }
  }, [searchParams]);

  return (
    <div className={styles.datesFilterContainer}>
      <div className={styles.datesFilterContainerSelect}>
        <Select
          value={selectedDateOption}
          placeholder="Выберите дату"
          isSearchable={true}
          options={dateRangeOptions}
          onSet={(value) => {
            setSelectedDateOptions(value);
            const range = getDateRange(value as SelectedDateRange);
            if (range) {
              setSearchParams(_params => {
                  _params.set(params.date_after, range.startDate);
                _params.set(params.date_before, range.endDate);
                _params.set(params.page, '1');
                return _params;
              });
            }
          }}
        />
      </div>
      {selectedDateOption === SelectedDateRange.CustomDate && <>
          <DatePicker
            label="Дата от"
            value={stringToDayJs(searchParams.get(params.date_after))}
            onChange={(e) => setSearchParams(_params => {
              _params.set(params.date_after, formatTimestampToServerDate(e.toString()));
              _params.set(params.page, '1');
              return _params;
            })}
          />
          <DatePicker
            label="Дата до"
            value={stringToDayJs(searchParams.get(params.date_before))}
            onChange={(e) => setSearchParams(_params => {
              _params.set(params.date_before, formatTimestampToServerDate(e.toString()));
              _params.set(params.page, '1');
              return _params;
            })}
          />
        </>
      }
    </div>
  );
};