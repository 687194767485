const SvgSelector = ({ id }) => {
    switch (id) {


        case 'expiring-domain-icon': {
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                          fill="#F68E12"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M12 6C12.5523 6 13 6.44772 13 7V11.5858L15.2071 13.7929C15.5976 14.1834 15.5976 14.8166 15.2071 15.2071C14.8166 15.5976 14.1834 15.5976 13.7929 15.2071L11.2929 12.7071C11.1054 12.5196 11 12.2652 11 12V7C11 6.44772 11.4477 6 12 6Z"
                          fill="#F68E12"/>
                </svg>
            )
        }

        case 'expired-domain-icon': {
            return (
                <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M11 5.25C11.4142 5.25 11.75 5.58579 11.75 6V11C11.75 11.4142 11.4142 11.75 11 11.75C10.5858 11.75 10.25 11.4142 10.25 11V6C10.25 5.58579 10.5858 5.25 11 5.25Z"
                        fill="#D11A2B"/>
                    <path
                        d="M11 15C11.5523 15 12 14.5523 12 14C12 13.4477 11.5523 13 11 13C10.4477 13 10 13.4477 10 14C10 14.5523 10.4477 15 11 15Z"
                        fill="#D11A2B"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M7.29442 2.47643C8.36633 1.11493 9.50183 0.25 11 0.25C12.4982 0.25 13.6337 1.11493 14.7056 2.47643C15.7598 3.81544 16.8769 5.79622 18.3063 8.33053L18.7418 9.10267C19.9234 11.1976 20.8566 12.8523 21.3468 14.1804C21.8478 15.5376 21.9668 16.7699 21.209 17.8569C20.4736 18.9118 19.2467 19.3434 17.6992 19.5471C16.1576 19.75 14.0845 19.75 11.4248 19.75H10.5752C7.91554 19.75 5.8424 19.75 4.30083 19.5471C2.75332 19.3434 1.52638 18.9118 0.791005 17.8569C0.0331946 16.7699 0.152198 15.5376 0.65315 14.1804C1.14335 12.8523 2.0766 11.1977 3.2582 9.1027L3.69363 8.33067C5.12301 5.79629 6.2402 3.81547 7.29442 2.47643ZM8.47299 3.40432C7.49897 4.64148 6.43706 6.51988 4.96496 9.12994L4.60131 9.77472C3.37508 11.9488 2.50369 13.4986 2.06035 14.6998C1.62272 15.8855 1.68339 16.5141 2.0215 16.9991C2.38204 17.5163 3.05874 17.8706 4.4966 18.0599C5.9286 18.2484 7.90262 18.25 10.6363 18.25H11.3637C14.0974 18.25 16.0714 18.2484 17.5034 18.0599C18.9412 17.8706 19.618 17.5163 19.9785 16.9991C20.3166 16.5141 20.3773 15.8855 19.9396 14.6998C19.4963 13.4986 18.6249 11.9488 17.3987 9.77471L17.035 9.12993C15.5629 6.51987 14.501 4.64148 13.527 3.40431C12.562 2.17865 11.8126 1.75 11 1.75C10.1874 1.75 9.43795 2.17865 8.47299 3.40432Z"
                          fill="#D11A2B"/>
                    <path
                        d="M11 5.25C11.4142 5.25 11.75 5.58579 11.75 6V11C11.75 11.4142 11.4142 11.75 11 11.75C10.5858 11.75 10.25 11.4142 10.25 11V6C10.25 5.58579 10.5858 5.25 11 5.25Z"
                        stroke="#D11A2B" strokeWidth="0.5"/>
                    <path
                        d="M11 15C11.5523 15 12 14.5523 12 14C12 13.4477 11.5523 13 11 13C10.4477 13 10 13.4477 10 14C10 14.5523 10.4477 15 11 15Z"
                        stroke="#D11A2B" strokeWidth="0.5"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M7.29442 2.47643C8.36633 1.11493 9.50183 0.25 11 0.25C12.4982 0.25 13.6337 1.11493 14.7056 2.47643C15.7598 3.81544 16.8769 5.79622 18.3063 8.33053L18.7418 9.10267C19.9234 11.1976 20.8566 12.8523 21.3468 14.1804C21.8478 15.5376 21.9668 16.7699 21.209 17.8569C20.4736 18.9118 19.2467 19.3434 17.6992 19.5471C16.1576 19.75 14.0845 19.75 11.4248 19.75H10.5752C7.91554 19.75 5.8424 19.75 4.30083 19.5471C2.75332 19.3434 1.52638 18.9118 0.791005 17.8569C0.0331946 16.7699 0.152198 15.5376 0.65315 14.1804C1.14335 12.8523 2.0766 11.1977 3.2582 9.1027L3.69363 8.33067C5.12301 5.79629 6.2402 3.81547 7.29442 2.47643ZM8.47299 3.40432C7.49897 4.64148 6.43706 6.51988 4.96496 9.12994L4.60131 9.77472C3.37508 11.9488 2.50369 13.4986 2.06035 14.6998C1.62272 15.8855 1.68339 16.5141 2.0215 16.9991C2.38204 17.5163 3.05874 17.8706 4.4966 18.0599C5.9286 18.2484 7.90262 18.25 10.6363 18.25H11.3637C14.0974 18.25 16.0714 18.2484 17.5034 18.0599C18.9412 17.8706 19.618 17.5163 19.9785 16.9991C20.3166 16.5141 20.3773 15.8855 19.9396 14.6998C19.4963 13.4986 18.6249 11.9488 17.3987 9.77471L17.035 9.12993C15.5629 6.51987 14.501 4.64148 13.527 3.40431C12.562 2.17865 11.8126 1.75 11 1.75C10.1874 1.75 9.43795 2.17865 8.47299 3.40432Z"
                          stroke="#D11A2B" strokeWidth="0.5"/>
                </svg>

            )
        }

        default:
            return null;
    }
}

export default SvgSelector;
