import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { enqueueSnackbar } from "notistack";

import { ErrorMessage, PWAGetListData, PwaState, PwaStatus, Status } from "../../types/api";
import {
  changeBulkPWAsStatus,
  changePwaStatus,
  deleteBulkPWAs,
  deletePWA as deletePWAApi,
  duplicatePWA,
  getCreatedPwaList,
  getPWAPayment,
} from "../../api/pwa";
import { getFiltersThunk, updateWalletBalance } from "./globalDataReducer";
import { SelectElement, SelectList } from "../../types/global";
import { getDefaultParams } from "../../api/helpers/scripts";
import { RootState } from "../store";

export enum ActivateStatus {
  Waiting,
  Processing,
  Success,
  NotEnoughMoney,
  AlreadyPaid,
  Error,
}

type InitialState = {
  pwaList: {
    data: PWAGetListData[],
    count: number,
  },
  checkedPWAs: Set<number>,
  filters: {
    searchValue: string,
    statusFilter: string | null,
    selectedDomains: SelectList,
    selectedCountries: SelectList,
    selectedVertical: SelectElement | null,
  },
}

const initialState: InitialState = {
  pwaList: {
    data: [],
    count: 0,
  },
  checkedPWAs: new Set(),
  filters: {
    searchValue: "",
    statusFilter: null,
    selectedDomains: [],
    selectedCountries: [],
    selectedVertical: null,
  },
};

export const DEFAULT_ROWS_PER_PAGE = 10;

function getPwaListParams() {
  const params = new URLSearchParams(window.location.search);
  return getDefaultParams(params, DEFAULT_ROWS_PER_PAGE);
}

export const getPwaListData = createAsyncThunk<
  Awaited<ReturnType<typeof getCreatedPwaList>>,
  void
>(
  'pwaListReducer/getPwaListData',
  async (_, { signal } ) => {
    return await getCreatedPwaList(getPwaListParams(), signal);
  },
);

export const deletePWA = createAsyncThunk<void, number, { state: RootState }>(
  'pwaListReducer/deletePWA',
  async (id, { dispatch }) => {
    await deletePWAApi(id);
    await dispatch(getPwaListData());
    await dispatch(getFiltersThunk());
  },
);

export const deleteSelectedPWAs = createAsyncThunk<
  void, void, { state: RootState }
>(
  'pwaListReducer/deleteSelectedPWAs',
  async (_, { dispatch, getState }) => {
    const state = getState();
    const checkedPWAList = [...state.pwaListReducer.checkedPWAs];
    await deleteBulkPWAs(checkedPWAList);
    await dispatch(getPwaListData());
    await dispatch(getFiltersThunk());
  },
);

export const changePWAStatus = createAsyncThunk<void, PwaStatus, { state: RootState }>(
  'pwaListReducer/changePWAStatus',
  async (data, { dispatch }) => {
    await changePwaStatus(data.pwa_id, data.state);
    await dispatch(getPwaListData());
  },
);

export const payForPWA = createAsyncThunk<
  boolean,
  number,
  { state: RootState, rejectValue: ErrorMessage }
>(
  'pwaListReducer/payForPWA',
  async (id, { dispatch, rejectWithValue }) => {
    try {
      dispatch(changePwaStatusAction({
        pwa_id: id,
        status: Status.in_process
      }));
      await getPWAPayment(id);
      await dispatch(updateWalletBalance());

      return true;
    } catch (err: any) {
      const error = err as AxiosError<ErrorMessage>;
      await dispatch(getPwaListData());
      throw rejectWithValue(error.response!.data);
    }
  },
);

export const duplicatePWAThunk = createAsyncThunk<void, number, { state: RootState }>(
  'pwaListReducer/duplicatePWA',
  async (id, { dispatch }) => {
    try {
      await duplicatePWA(id);
      await dispatch(getPwaListData());
      await dispatch(getFiltersThunk());
    } catch (error: any) {
      enqueueSnackbar('Вы достигли лимита по созданию PWA на сегодня. Лимит 5 PWA в день', { variant: 'error', style: { backgroundColor: '#D11A2B' } });
    }
  },
);

export const payForSelectedPWAs = createAsyncThunk<
  boolean | ActivateStatus,
  void,
  { state: RootState, rejectValue: ErrorMessage }
>(
  'pwaListReducer/payForSelectedPWAs',
  async (
    _,
    { dispatch, getState, rejectWithValue }
  ) => {
    const state = getState();
    const selectedPWAs = [...state.pwaListReducer.checkedPWAs];
    const createdPWAList = state.pwaListReducer.pwaList.data;
    const pwaToPay: number[] = [];
    for (const id of selectedPWAs) {
      const pwaData = createdPWAList.find(pwa => pwa.id === id)!;
      if (pwaData.status === "not_paid") {
        pwaToPay.push(id);
        dispatch(changePwaStatusAction({
          pwa_id: id,
          status: Status.in_process
        }));
      }
    }

    if (pwaToPay.length === 0) return ActivateStatus.AlreadyPaid;

    try {
      await Promise.all(pwaToPay.map(pwa => getPWAPayment(pwa)));
      await dispatch(getPwaListData());
      await dispatch(updateWalletBalance());

      return true;
    } catch (err: any) {
      await dispatch(getPwaListData());
      return ActivateStatus.Error;
    }
  },
);


export const changeSelectedPWAsStatus = createAsyncThunk<
  void, PwaState, { state: RootState }
>(
  'pwaListReducer/deleteSelectedPWAs',
  async (status, { dispatch, getState }) => {
    const state = getState();
    const checkedPWAList = [...state.pwaListReducer.checkedPWAs];
    await changeBulkPWAsStatus(checkedPWAList, status);
    await dispatch(getPwaListData());
  },
);

const pwaListReducer = createSlice({
  name: "pwaListReducer",
  initialState,
  reducers: {
    selectPWA(state, action: PayloadAction<number>) {
      state.checkedPWAs.add(action.payload);
    },
    deselectPWA(state, action: PayloadAction<number>) {
      state.checkedPWAs.delete(action.payload);
    },
    selectAllPWAs(state) {
      state.checkedPWAs = new Set(state.pwaList.data.map(item => item.id));
    },
    selectNoPWA(state) {
      state.checkedPWAs = new Set();
    },

    changePwaStatusAction(state, action: PayloadAction<{ pwa_id: number, status: string }>) {
      const index = state.pwaList.data.findIndex(pwa => pwa.id === action.payload.pwa_id);
      if (index !== -1) {
        state.pwaList.data[index].status = action.payload.status;
      }
    },

    changePwaDomainAndStatusAction(state, action: PayloadAction<{
      pwa_id: number,
      status: string,
      domain: any,
    }>) {
      const index = state.pwaList.data.findIndex(pwa => pwa.id === action.payload.pwa_id);
      if (index !== -1) {
        state.pwaList.data[index].status = action.payload.status;
        state.pwaList.data[index].domain = action.payload.domain;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPwaListData.fulfilled, (state, action) => {
      state.pwaList.data = action.payload.results;
      state.pwaList.count = action.payload.count;
    });

    builder.addCase(payForSelectedPWAs.fulfilled, (state) => {
      state.checkedPWAs = new Set();
    });
  }
});

export const getCheckedPWAList = (state: RootState) => state.pwaListReducer.checkedPWAs;
export const getCreatedPWAList = (state: RootState) => state.pwaListReducer.pwaList;


export const {
  selectPWA,
  deselectPWA,
  selectAllPWAs,
  selectNoPWA,
  changePwaStatusAction,
  changePwaDomainAndStatusAction,
} = pwaListReducer.actions;

export default pwaListReducer.reducer;
